import $ from "jquery";

if ($(window).width() > 991) {
    $('.sub-menu .menu-item').css('min-width', $('.menu-item-has-children').width());
}

var counterHamburger = 1;
$('.hamburger').click(function(){
    counterHamburger++;
    if (counterHamburger % 2 === 0){
        $('.hamburger').addClass('click');
        $('body').css({'overflow': 'hidden'});
        $('.menu').removeClass('hide').addClass('show');
        $('.header__nav').addClass('show');
    } else {
        $('.hamburger').removeClass('click');
        $('body').css({'overflow': 'auto'});
        $('.menu').removeClass('show').addClass('hide');
        $('.header__nav').removeClass('show');
    }
});