import $ from 'jquery'

$(".card__block--man").on("click", function() {
    var a = $(this).attr('id');
    $(".card__block--man").removeClass("card__block--active");
    $(".card__block--man").removeClass("active");
    $(".card__main--man").removeClass("active");
    $(this).addClass("active");
    $(this).addClass("card__block--active");
    $("#" + a.toString() + '-show').addClass("active");
    var destination = $("#top").offset().top;
    $("html:not(:animated),body:not(:animated)").animate({
        scrollTop: destination
    }, 800);
    return false;
});